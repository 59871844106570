<template>
  <v-container id="dashboard" fluid tag="section" style="padding-bottom: 150px">
    <v-row justify="center">
      <v-col cols="12" md="12">
        <base-material-card class="px-5 py-3">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">PropCheck Dashboard</div>

            <div class="text-subtitle-1 font-weight-light">Property Reports</div>
          </template>
          <v-card-text>
            <v-data-table :headers="headers" :items="items">
              <template v-slot:[`item.intelId`]="{ item }">
                <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span
                    v-on="on"
                  >{{item.intelId}}</span>
                </template>
                <span>UID: {{item.id}}</span>
              </v-tooltip>
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ item.status }}</span>
                  </template>
                  <div v-if="item.status === 'Failed to Generate Report' || item.status === 'Failed - Unable to complete'">
                    <span v-if="item.autoRetryCount && item.status === 'Failed to Generate Report'">
                      Note: Report will automatically retry generation over the next few hours.<br/>
                      Retry Count: {{item.autoRetryCount}}
                    </span>
                    <span v-if="!item.autoRetryCount && item.status === 'Failed to Generate Report'">
                      Note: Report will automatically retry generation over the next few hours.
                    </span>
                    <span v-if="item.status === 'Failed - Unable to complete'">
                      Sorry, we were unable to complete this report generation at this time. 
                      <br/> Any report credits have been restored to your account.
                    </span>
                  </div>
                </v-tooltip>
              </template>

              <template v-slot:[`item.generatedDate`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ formatDate(item.generatedDate) }}</span>

                  </template>
                  <span v-if="item.updateDate"
                    >Last Updated: {{ formatDate(item.updateDate) }} <br />
                    Processing Time: {{ formatTimeToGenerate(item) }}</span
                  >
                  <span v-if="!item.updateDate">Generating...</span>
                </v-tooltip>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip v-if="item.status == 'Insufficient Credits Remaining'" top>
                <template v-slot:activator="{ on, attrs }">                
                    <v-btn
                      icon
                      color="primary"
                      class="action-btn"
                      v-bind="attrs"
                      v-on="on"
                      @click="addCredits(item)"
                    >
                      <v-icon>mdi-cash-100</v-icon>
                    </v-btn>                
                  </template>
                  <span>Add more credits</span>
                </v-tooltip>
                                
                <v-tooltip v-if="item.status == 'Generated Report' || item.status == 'Sending Report Email' || item.status == 'Completed'" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      class="action-btn"
                      v-bind="attrs"
                      v-on="on"
                      @click="viewReport(item)"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Download Report</span>
                </v-tooltip>

                <v-tooltip v-if="user.roles == 'business' && (item.status == 'Failed to Generate Report' || item.status == 'Completed' || item.status == 'Insufficient Credits Remaining')" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      class="action-btn"
                      v-bind="attrs"
                      v-on="on"
                      @click="regenerateReport(item)"
                    >
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>Regenerate Report</span>
                </v-tooltip>

                <v-tooltip v-if="user.roles == 'business' && (item.status == 'Failed to Generate Report' || item.status == 'Completed' || item.status == 'Insufficient Credits Remaining')" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      class="action-btn"
                      v-bind="attrs"
                      v-on="on"
                      @click="regenerateReportMaps(item)"                      
                    >
                      <v-icon>mdi-map</v-icon>
                    </v-btn>
                  </template>
                  <span>Regenerate Report Maps</span>
                </v-tooltip>

                <v-tooltip v-if="item.status == 'Sending Report Email' || item.status == 'Completed'" top>
                  <template v-slot:activator="{ on, attrs }">                
                    <v-btn
                      icon
                      color="primary"
                      class="action-btn"
                      v-bind="attrs"
                      v-on="on"
                      @click="resendReport(item)"
                    >
                      <v-icon>mdi-email-sync</v-icon>
                    </v-btn>
                  </template>
                  <span>Resend email</span>
                </v-tooltip>

              </template>
            </v-data-table>
          </v-card-text>

          <v-card-text style="height: 100px; position: relative">
            <v-fab-transition>
            <v-btn
              key="create"
              color="primary"
              fab
              large
              dark
              bottom
              right
              absolute
              class="v-btn--example"
              @click="search"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-fab-transition>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { find } from "lodash";
import { mapState } from "vuex";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import moment from "moment";

const auth = firebase.auth();
const storage = firebase.storage();
const storageRef = storage.ref();
const db = firebase.firestore();

export default {
  name: "UserDashboard",

  data() {
    return {
      headers: [
        {
          text: "ID",
          value: "intelId",
        },
        {
          text: "Address",
          value: "addressLine1",
        },
        {
          sortable: false,
          text: "Lot/Plan",
          value: "lotPlan",
        },
        {
          sortable: true,
          text: "Locality",
          value: "addressLine2",
        },
        {
          sortable: true,
          text: "Date Generated",
          value: "generatedDate",
        },
        {
          sortable: false,
          text: "Status",
          value: "status",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      items: [],
      reports: [],
    };
  },

  computed: {
    ...mapState(["user"]),
  },

  firestore() {
    db.collection("reports")
      .where("userId", "==", auth.currentUser.uid)
      .orderBy("generatedDate", "desc")
      .onSnapshot(
        (querySnapshot) => {
          const documents = querySnapshot.docs.map((doc) => {
            const item = doc.data();
            item.id = doc.id;
            return item;
          });
          this.items = documents;
          this.reports = documents.map((data) => {
            return {
              id: data.id,
              intelId: data.intelId,
              filename: data.filename,
              fileRef: data.destination,
            };
          });
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.error("Error fetching documents: ", error);
        }
      );
  },

  methods: {
    viewReport(item) {
      const report = find(this.reports, (i) => {
        return i.intelId === item.intelId;
      });

      const reportRef = storageRef.child(report.fileRef);
      reportRef
        .getDownloadURL()
        .then((url) => {
          var xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = (event) => {
            var blob = xhr.response;
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = report.filename;
            link.click();
            URL.revokeObjectURL(link.href);
          };
          xhr.open("GET", url);
          xhr.send();
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(`Error ::: ${error}`);
        });
    },

    regenerateReport(item) {
      db.collection("reports").doc(item.id).set(
        {
          status: "Queued For Generation",
        },
        { merge: true }
      );
    },

    regenerateReportMaps(item) {
      db.collection("reports").doc(item.id).set(
        {
          status: "Fetched Report Data",
        },
        { merge: true }
      );
    },

    addCredits(item){
      this.$router.push({ name: "Subscribe" });
    },

    search(item){
      this.$router.push({ name: "Property Search" });
    },

    resendReport(item) {
      db.collection("reports").doc(item.id).set(
        {
          status: "Generated Report",
        },
        { merge: true }
      );
    },

    formatDate(date) {
      var d = new Date(date).toLocaleDateString("en-au");
      var t = new Date(date).toLocaleTimeString("en-au");

      return `${d} ${t}`;
    },

    formatTimeToGenerate(item) {
      const start = moment(item.generatedDate);
      const complete = moment(item.updateDate);
      return moment.duration(start.diff(complete)).humanize();
    },
  },
};
</script>

<style scoped>
.action-btn {
  background-color: #fff !important;
}
</style>
